import { Button } from "@mui/material"
import { format } from "date-fns"
import { FC, useContext } from "react"
import { useAssetDetailsPageQuery } from "../../../graphql/generated/client-types-and-hooks"
import { formatMoney } from "../../../helpers/formatMoney"
import { getDateFromDateString } from "../../../helpers/getDateFromDateString"
import { useHandleError } from "../../../hooks/useHandleError"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { H5 } from "../../Elements"
import { LabeledSection } from "../../LabeledSection"
import { PageTitle } from "../../PageTitle"
import { AssetEditDrawer } from "../Drawer/AssetEditDrawer"
import { AssetSkeleton } from "./Asset.skeleton"
import { InventoryIntervalLookup } from "./CreateOrEditAssetForm/InventoryIntervalOptions"

type Props = {
  assetId: string
}

type ValueOrNoneLabelProps = {
  value?: string | number | null | undefined
  className?: string
}

export const ValueOrNoneLabel: FC<ValueOrNoneLabelProps> = ({ value, className }) => {
  return value ? <span className={className}>{value}</span> : <span className="text-gray-500">None</span>
}

export const BasicInfo: FC<Props> = ({ assetId }) => {
  const { push: pushDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)
  const [{ data, fetching, error }] = useAssetDetailsPageQuery({
    variables: { id: assetId },
  })
  useHandleError(error, "An error occurred while fetching asset details.")

  const isGroupParent = data?.asset?.isAssetGroup
  const inspectionAssignment = data?.asset?.inspectionTemplatesAssignments?.find((a) => a.assetId === assetId)

  if (fetching || !data?.asset) {
    return <AssetSkeleton />
  }

  return (
    <div className="flex flex-col gap-9">
      <PageTitle title={`${data.asset.name} details`} />
      <div>
        <div className="flex justify-between items-center min-h-[40px]">
          <H5 className="mt-0 mb-0">Basic Info</H5>
          {hasPermissionTo("asset:update") && (
            <Button variant="text" onClick={() => pushDrawer(<AssetEditDrawer asset={data.asset} />)}>
              Edit
            </Button>
          )}
        </div>
        <hr className="mt-2 mb-4 md:mb-6" />
        <div className="grid gap-y-1">
          <div className="md:grid md:grid-cols-2 md:gap-4 grid gap-y-4">
            <LabeledSection label="Asset Name">
              <ValueOrNoneLabel value={data.asset.name} />
            </LabeledSection>
            {!isGroupParent && (
              <>
                <LabeledSection label="Year">
                  <ValueOrNoneLabel value={data.asset.manufacturer?.year} />
                </LabeledSection>
                <LabeledSection label="Asset Number">
                  <ValueOrNoneLabel value={data.asset.companyAssetNumber} />
                </LabeledSection>
                <LabeledSection label="Manufacturer">
                  <ValueOrNoneLabel value={data.asset.manufacturer?.make} />
                </LabeledSection>
                <LabeledSection label="Vin / Serial #">
                  <ValueOrNoneLabel value={data.asset.manufacturer?.id} />
                </LabeledSection>
                <LabeledSection label="Model">
                  <ValueOrNoneLabel value={data.asset.manufacturer?.model} />
                </LabeledSection>
                <DevelopmentFeatureFlag name="Asset Category">
                  <LabeledSection label="Category">
                    <ValueOrNoneLabel value={data.asset.category} />
                  </LabeledSection>
                </DevelopmentFeatureFlag>
              </>
            )}
          </div>

          {isGroupParent || (
            <>
              <H5 className="mt-8">Owned / Rented</H5>
              <div className="grid gap-y-4">
                <LabeledSection label="Owned / Rented">
                  {data.asset.ownershipType === "OWN" ? "Owned" : "Rented"}
                </LabeledSection>
                {data.asset.ownershipType === "OWN" && (
                  <>
                    <LabeledSection label="Purchase Price">
                      <ValueOrNoneLabel value={formatMoney(data.asset.purchaseDetails?.price)} />
                    </LabeledSection>
                    <LabeledSection label="Purchase Date">
                      <ValueOrNoneLabel value={data.asset.purchaseDetails?.date} />
                    </LabeledSection>
                  </>
                )}
                {data.asset.ownershipType === "RENT" && (
                  <>
                    <LabeledSection label="Start & End Date">
                      <ValueOrNoneLabel
                        value={
                          data.asset.rentalAgreement?.startOn
                            ? format(getDateFromDateString(data.asset.rentalAgreement.startOn), "MM/dd/yyyy")
                            : undefined
                        }
                      />
                      &nbsp;-&nbsp;
                      <ValueOrNoneLabel
                        value={
                          data.asset.rentalAgreement?.endOn
                            ? format(getDateFromDateString(data.asset.rentalAgreement.endOn), "MM/dd/yyyy")
                            : undefined
                        }
                      />
                    </LabeledSection>
                    <LabeledSection label="Rates (Day, Week, 4-Week)">
                      <ValueOrNoneLabel value={formatMoney(data.asset.rentalAgreement?.rate?.daily)} />
                      &nbsp;&middot;&nbsp;
                      <ValueOrNoneLabel value={formatMoney(data.asset.rentalAgreement?.rate?.weekly)} />
                      &nbsp;&middot;&nbsp;
                      <ValueOrNoneLabel value={formatMoney(data.asset.rentalAgreement?.rate?.monthly)} />
                    </LabeledSection>
                    <LabeledSection label="Vendor Name">
                      <ValueOrNoneLabel value={data.asset.vendorContact?.name} />
                    </LabeledSection>
                    <LabeledSection label="Vendor Email">
                      <ValueOrNoneLabel value={data.asset.vendorContact?.email} />
                    </LabeledSection>
                    <LabeledSection label="Vendor Phone">
                      <ValueOrNoneLabel value={data.asset.vendorContact?.phone} />
                    </LabeledSection>
                  </>
                )}
              </div>
            </>
          )}
          <H5 className="mt-8">Inventory Settings</H5>
          <div className="grid gap-y-4">
            <LabeledSection label="Schedule Frequency">
              <ValueOrNoneLabel
                value={
                  data.asset.inventoryRequirements?.intervalInSeconds &&
                  InventoryIntervalLookup[data.asset.inventoryRequirements?.intervalInSeconds]
                }
              />
            </LabeledSection>
            <LabeledSection label="Photo Required">
              <ValueOrNoneLabel value={data.asset.inventoryRequirements?.photoRequired ? "Yes" : "No"} />
            </LabeledSection>
          </div>
          <>
            <H5 className="mt-8">Inspection Checklist</H5>
            <div className="grid gap-y-4">
              <LabeledSection label="Templates">
                {data.asset?.inspectionTemplatesAssignments?.length ? (
                  data.asset.inspectionTemplatesAssignments?.map(({ inspectionTemplate: { id, name } }) => (
                    <ValueOrNoneLabel key={id} value={name} />
                  ))
                ) : (
                  <ValueOrNoneLabel value={undefined} />
                )}
              </LabeledSection>
              <LabeledSection label="Schedule Frequency">
                <ValueOrNoneLabel
                  value={
                    inspectionAssignment?.intervalInSeconds &&
                    InventoryIntervalLookup[inspectionAssignment?.intervalInSeconds]
                  }
                />
              </LabeledSection>
              <LabeledSection label="Start Date">
                <ValueOrNoneLabel
                  value={inspectionAssignment?.startDate ? format(inspectionAssignment.startDate, "yyyy-MM-dd") : ""}
                />
              </LabeledSection>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}
