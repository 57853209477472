import { FC, ReactNode } from "react"
import { IconType } from "react-icons"
import {
  BiBadgeCheck,
  BiBandAid,
  BiBuilding,
  BiBullseye,
  BiCalendar,
  BiCalendarEvent,
  BiCalendarPlus,
  BiCalendarStar,
  BiCalendarWeek,
  BiCalendarX,
  BiCar,
  BiClipboard,
  BiCoffee,
  BiCreditCardFront,
  BiDollarCircle,
  BiEnvelope,
  BiHash,
  BiIdCard,
  BiImage,
  BiInfoSquare,
  BiLayer,
  BiListUl,
  BiMap,
  BiMapPin,
  BiMessageSquareDetail,
  BiMobileAlt,
  BiNote,
  BiNotepad,
  BiPurchaseTag,
  BiSolidArrowToTop,
  BiSolidWatch,
  BiTagAlt,
  BiTimeFive,
  BiUserPin,
} from "react-icons/bi"
import { classNames } from "../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
  circularIcon?: boolean
  label: string | number
  note?: boolean
  hideIcon?: boolean
  icon?: IconType
  imageUrl?: string | null
  hideLabel?: boolean
}

type IconLabels = keyof typeof icons

const icons = {
  Address: BiMapPin,
  "Asset Name": BiMessageSquareDetail,
  "Asset Number": BiHash,
  "Company User ID": BiIdCard,
  Coordinates: BiMap,
  Category: BiCar,
  Description: BiNotepad,
  Email: BiEnvelope,
  "End Date": BiCalendarWeek,
  "Estimated Hours": BiSolidWatch,
  Holidays: BiCalendarX,
  ID: BiHash,
  "Job Title": BiTagAlt,
  "Location Notes": BiNote,
  "Man-Hours": BiSolidWatch,
  Manufacturer: BiBuilding,
  Model: BiInfoSquare,
  Name: BiUserPin,
  "Owned / Rented": BiPurchaseTag,
  "Parent task": BiSolidArrowToTop,
  "Parent Task": BiSolidArrowToTop,
  Phone: BiMobileAlt,
  "Photo Required": BiImage,
  "Project Code": BiHash,
  "Project Name": BiLayer,
  "Purchase Date": BiCalendarEvent,
  "Purchase Price": BiDollarCircle,
  "Project Radius": BiBullseye,
  Role: BiBadgeCheck,
  "Schedule Frequency": BiCalendarWeek,
  "Scheduled Break": BiCoffee,
  "Start & End Date": BiCalendarPlus,
  "Start Date": BiCalendarWeek,
  "Summary Task Name": BiListUl,
  "Task Name": BiListUl,
  Templates: BiClipboard,
  "Vin / Serial #": BiCreditCardFront,
  "Workers Comp Code": BiBandAid,
  "Work Days": BiCalendar,
  "Work Hours": BiTimeFive,
  Year: BiCalendarStar,
  Pay: BiDollarCircle,
}

export const LabeledSection: FC<Props> = ({
  label,
  circularIcon,
  className,
  children,
  imageUrl = "",
  note,
  hideIcon,
  hideLabel = false,
}) => {
  const IconComponent = icons[label as IconLabels]
  const labelClassName = "text-gray-800 font-medium text-base mr-3"
  const childrenClassName = "text-gray-500 font-normal text-base"
  const inlineText = label === "Description" || label === "Location Notes"

  return (
    <div className={classNames(!hideIcon && "flex", className)}>
      {!hideIcon && (
        <div
          className={classNames(
            "h-10 min-w-[40px] mr-3 flex bg-gray-100",
            circularIcon ? "rounded-full" : "rounded-lg"
          )}
        >
          {IconComponent && <IconComponent className="h-5 w-[20px] m-auto" />}
        </div>
      )}
      {imageUrl && (
        // eslint-disable-next-line @next/next/no-img-element
        <img alt={`${label}`} src={imageUrl} className="h-10 w-10 rounded-lg" />
      )}
      <div className={classNames("flex", note ? "flex-col items-start" : " items-center")}>
        {inlineText ? (
          <div className={classNames(labelClassName, "pt-[10px]")}>
            Description <span className={classNames(childrenClassName, "ml-2")}>{children}</span>
          </div>
        ) : (
          <>
            {!hideLabel && <p className={classNames(labelClassName, note && "pb-2")}>{label}</p>}
            <div className={childrenClassName}>{children}</div>
          </>
        )}
      </div>
    </div>
  )
}
