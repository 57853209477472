import { Button } from "@mui/material"
import { FC, useEffect, useRef, useState } from "react"
import { BiImage } from "react-icons/bi"
import { v4 as uuidV4 } from "uuid"
import { classNames } from "../helpers/classNames"
import { ImageUploadPreview } from "./ImageUploadPreview"

type ImageUploadProps = {
  onFileSelected: (fileList: File[]) => void
  accept?: string
  initialImageSrc?: string
  imageSrc?: string
  disabled?: boolean
  className?: string
  progressCounter?: {
    completed: number
    total: number
  }
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function changeFileName(file: File, name: string) {
  return new File([file], name, {
    type: file.type,
    lastModified: file.lastModified,
  })
}

export const ImageUpload: FC<ImageUploadProps> = ({
  accept = "image/*",
  disabled = false,
  className,
  imageSrc,
  initialImageSrc,
  onDelete,
  onFileSelected,
}) => {
  const dropZoneRef = useRef<HTMLLabelElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const previewImageSrc = imageSrc || initialImageSrc

  const handleDrop = (newFiles: FileList) => {
    if (newFiles.length) {
      const data = [newFiles[0]]
      const renamed = data.map((file) => changeFileName(file, uuidV4()))
      onFileSelected(renamed)
    }
  }

  useEffect(() => {
    const dropZone = dropZoneRef.current
    const dragStart = (event: DragEvent) => {
      event.preventDefault()
      setIsDragging(true)
    }
    const dragEnd = (event: DragEvent) => {
      event.preventDefault()
      setIsDragging(false)
    }
    const drop = (event: DragEvent) => {
      event.preventDefault()
      setIsDragging(false)
      if (event.dataTransfer) {
        handleDrop(event.dataTransfer?.files)
      }
    }

    if (dropZone) {
      dropZone.addEventListener("dragenter", dragStart)
      dropZone.addEventListener("dragover", dragStart)
      dropZone.addEventListener("dragleave", dragEnd)
      dropZone.addEventListener("drop", drop)

      return () => {
        dropZone.removeEventListener("dragenter", dragStart)
        dropZone.removeEventListener("dragover", dragStart)
        dropZone.removeEventListener("dragleave", dragEnd)
        dropZone.removeEventListener("drop", drop)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropZoneRef?.current])

  return (
    <label
      className={classNames(
        "block p-6 rounded-md border border-dashed border-gray-400 text-gray-400 text-left cursor-pointer transition-colors",
        "hover:bg-gray-50",
        isDragging ? "bg-gray-200 opacity-50" : "",
        disabled && "opacity-40 cursor-default",
        className
      )}
      ref={dropZoneRef}
    >
      <div className="flex justify-between">
        <div className="flex gap-x-4 items-center">
          {!previewImageSrc && <BiImage className="mx-auto w-10 h-10 text-gray-300" />}
          {previewImageSrc && previewImageSrc !== "" && (
            <ImageUploadPreview imageSrc={previewImageSrc} onDelete={onDelete} className="h-10 w-10" />
          )}
          <p className="font-medium hidden md:block">Upload or drag file here</p>
        </div>
        <Button color="black" component="label" htmlFor="image-upload-file-input" variant="contained">
          Add photo
          <input
            id="image-upload-file-input"
            disabled={disabled}
            className="hidden"
            type="file"
            accept={accept}
            onChange={(e) => {
              if (e.target.files) {
                handleDrop(e.target.files)
              }
            }}
          />
        </Button>
      </div>
    </label>
  )
}
