import { FC } from "react"
import { classNames } from "../../helpers/classNames"

type Props = {
  className: string
}

export const SkeletonCard: FC<Props> = ({ className }) => {
  return <div className={classNames("bg-gray-200 rounded-lg", className)} />
}
