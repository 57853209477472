import { Button, Grid } from "@mui/material"
import { format } from "date-fns"
import { Form, Formik } from "formik"
import { FC, useContext, useState } from "react"
import * as Yup from "yup"
import {
  Asset,
  AssetCategory,
  AssetInspectionTemplateAssignments,
  AssetReportTemplate,
  useAssetCreateMutation,
  useAssetEditMutation,
  useCreateOrEditAssetFormQueryQuery,
} from "../../../graphql/generated/client-types-and-hooks"
import { assetCategoryOptions } from "../../../helpers/assetCategory"
import { dataURLToBlob } from "../../../helpers/dataURLToBlob"
import { fileUpload } from "../../../helpers/fileUpload"
import { formatMoney } from "../../../helpers/formatMoney"
import { formatNumberFromMoney } from "../../../helpers/formatNumberFromMoney"
import { getDataUrlForImage } from "../../../helpers/getDataUrlForImage"
import { getDateFromDateString } from "../../../helpers/getDateFromDateString"
import { useHandleError } from "../../../hooks/useHandleError"
import { PickPlus } from "../../../types/helpers"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { ButtonFilled, H2, H5 } from "../../Elements"
import { FormRow } from "../../FormRow"
import { ChipSelect } from "../../Formik/ChipSelect"
import { DatePicker } from "../../Formik/DatePicker"
import { RadioGroup } from "../../Formik/RadioGroup"
import { TextField } from "../../Formik/TextField"
import { ImageUpload } from "../../ImageUpload2"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { AssetInspectionForm } from "../Assets/CreateOrEditAssetForm/AssetInspectionForm"
import {
  InventoryRequirementsForm,
  handleInventoryRequirementsSubmission,
} from "../Assets/CreateOrEditAssetForm/InventoryRequirementsForm"
import {
  InspectionRequirementsValidationSchema,
  InventoryRequirementsValidationSchema,
} from "../Assets/validationSchemas"
import { DrawerFooter } from "./DrawerFooter"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerHeader } from "./DrawerHeader"

// @todo - We should try to use the generated types for this.
// I spent some time working on it, but it's not trivial.
type Values = {
  name: string
  category: AssetCategory
  companyAssetNumber: string
  projectId: string[]
  ownershipType: Asset["ownershipType"]
  rentalAgreement: {
    startOn?: Date | string
    endOn?: Date | string
    rate?: {
      daily?: string
      weekly?: string
      monthly?: string
    }
  }
  purchaseDetails?: {
    date?: Date | string
    price?: string
  }
  manufacturer: {
    id?: string | null
    make?: string | null
    name?: string | null
    year?: string
    model?: string | null
  }
  vendorContact: Asset["vendorContact"]
  inventoryRequirements: {
    photoRequired?: boolean
    intervalInSeconds?: string
  }
  assetGroupId?: string[]
  groupQuantity?: string
  isAssetGroup: boolean
  inspectionRequirements: {
    assetReportTemplateId?: string
    intervalInSeconds?: string
    startDate?: Date
  }
}

type InspectionTemplatesAssignments = (PickPlus<
  AssetInspectionTemplateAssignments,
  "id" | "assetId" | "intervalInSeconds" | "lastReportedAt" | "startDate"
> & { inspectionTemplate: PickPlus<AssetReportTemplate, "id" | "name"> })[]

type Props = {
  asset: PickPlus<
    Asset,
    | "id"
    | "imageUrl"
    | "name"
    | "assetGroupId"
    | "companyAssetNumber"
    | "groupQuantity"
    | "isAssetGroup"
    | "manufacturer"
    | "inventoryRequirements"
    | "ownershipType"
    | "purchaseDetails"
    | "rentalAgreement"
    | "vendorContact"
    | "active"
    | "activeChangedAt"
    | "assetChildCount"
    | "assetGroupMemberCount"
    | "category"
  > & {
    inspectionTemplatesAssignments: InspectionTemplatesAssignments
  }
}

type UniqueIdErrors = {
  companyAssetNumber?: string
  manufacturer?: { id?: string }
}

export const AssetEditDrawer: FC<Props> = ({ asset }) => {
  const [{ error, fetching: editIsFetching }, editAssetMutation] = useAssetEditMutation()
  const [{ error: createError, fetching: createIsLoading }] = useAssetCreateMutation()
  useHandleError(error, "There was an error saving the asset.")
  useHandleError(createError, "There was an error saving the asset.")

  const { handleClose } = useContext(SingleDrawerContext)
  const [assetImageSrc, setAssetImageSrc] = useState<string>("")
  const [initialAssetImageSrc, setInitialAssetImageSrc] = useState<string>(asset?.imageUrl || "")

  const handleValidationErrors = async (message: string, setErrors: (args: UniqueIdErrors) => void) => {
    let error: UniqueIdErrors = {}
    const [errorType] = message.split(":")

    switch (errorType) {
      case "DUPLICATE_VIN":
        error.manufacturer = { id: "This VIN / Serial number is already in use by another asset" }
        setErrors(error)
        break
      case "DUPLICATE_ASSET_NUMBER":
        error.companyAssetNumber = "This asset number is already in use by another asset"
        setErrors(error)
        break
      default:
        break
    }
  }

  const [{ data: queryData, error: assetsError }] = useCreateOrEditAssetFormQueryQuery()

  const assets = queryData?.assets || []

  useHandleError(assetsError, "There was an error loading assets.")

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required").label("Name"),

    category: Yup.mixed<AssetCategory>().oneOf(Object.values(AssetCategory), "Invalid Category").required(),

    companyAssetNumber: Yup.string()
      .trim()
      .label("Company asset number")
      .nullable()
      .test("isUnique", "There is another asset with this number", (companyAssetNumber) =>
        assets
          .filter((a) => (asset ? a.id !== asset.id : true))
          .every((asset) => asset?.companyAssetNumber !== companyAssetNumber)
      ),
    projectId: Yup.array().required("Required"),
    groupQuantity: Yup.number()
      .integer()
      .moreThan(-1)
      .when("assetGroupId", {
        is: (ids: (string | undefined)[]) => ids.some((id) => !!id),
        then: (schema) => schema.oneOf([undefined], "Quantity cannot be specified on creation of a linked asset"),
      }),
    inventoryRequirements: InventoryRequirementsValidationSchema,
    inspectionRequirements: InspectionRequirementsValidationSchema,
    manufacturer: Yup.object().shape({
      id: Yup.string()
        .trim()
        .label("Vin / Serial number")
        .nullable()
        .test("isUnique", "There is another asset with this VIN / Serial number", (id) =>
          assets
            .filter((a) => (asset ? a.id !== asset.id : true))
            .every((asset) => (id ? asset?.manufacturer?.id !== id : true))
        ),
      year: Yup.string().label("Year").nullable(),
      make: Yup.string().trim().label("Manufacturer").nullable(),
      model: Yup.string().trim().label("Model").nullable(),
    }),
    ownershipType: Yup.string().trim().required("Required").label("Ownership type"),
    purchaseDetails: Yup.object().shape({
      date: Yup.date().label("Purchase date").nullable(),
      price: Yup.string().label("Purchase price").nullable(),
    }),
    rentalAgreement: Yup.object().when("ownershipType", {
      is: "OWN",
      then: (schema) => schema.shape({}).notRequired(),
      otherwise: (schema) =>
        schema.shape({
          startOn: Yup.date().label("Start date").nullable(),
          endOn: Yup.date().label("End date").nullable().default(null),
          rate: Yup.object()
            .shape({
              daily: Yup.string().label("Daily rate").nullable().default(null),
              weekly: Yup.string().label("Daily rate").nullable().default(null),
              monthly: Yup.string().label("Daily rate").nullable().default(null),
            })
            .nullable(),
        }),
    }),
    vendorContact: Yup.object().shape({
      name: Yup.string().trim().label("Name").nullable(),
      email: Yup.string().trim().label("Email").nullable(),
      phone: Yup.string().trim().label("Phone").nullable(),
    }),
  })

  const inspectionAssignment = asset?.inspectionTemplatesAssignments?.find((x) => x.assetId === asset.id)

  const initialValues = {
    assetGroupId: asset?.assetGroupId ? [asset.assetGroupId] : [""],
    companyAssetNumber: asset?.companyAssetNumber || "",
    groupQuantity: asset?.groupQuantity?.toString() || "1",
    isAssetGroup: asset?.isAssetGroup || false,
    name: asset?.name || "",
    projectId: [""],
    inventoryRequirements: asset?.inventoryRequirements
      ? {
          photoRequired: asset.inventoryRequirements.photoRequired || false,
          intervalInSeconds: asset.inventoryRequirements.intervalInSeconds?.toString(),
        }
      : { intervalInSeconds: undefined, photoRequired: false },
    inspectionRequirements: {
      intervalInSeconds: inspectionAssignment?.intervalInSeconds?.toString() || "",
      startDate: inspectionAssignment?.startDate || undefined,
      inspectionRequired: false,
    },
    manufacturer: { ...asset?.manufacturer, year: asset?.manufacturer?.year?.toString() || "" } || {
      year: "",
    },
    ownershipType: asset?.ownershipType || "OWN",
    purchaseDetails: asset?.purchaseDetails
      ? {
          date: asset.purchaseDetails.date ? getDateFromDateString(asset.purchaseDetails.date) : "",
          price: formatMoney(asset.purchaseDetails.price),
        }
      : {},
    rentalAgreement: asset?.rentalAgreement
      ? {
          startOn: asset.rentalAgreement.startOn ? getDateFromDateString(asset.rentalAgreement.startOn) : "",
          endOn: asset.rentalAgreement.endOn ? getDateFromDateString(asset.rentalAgreement.endOn) : "",
          rate: {
            daily: formatMoney(asset.rentalAgreement?.rate?.daily),
            weekly: formatMoney(asset.rentalAgreement?.rate?.weekly),
            monthly: formatMoney(asset.rentalAgreement?.rate?.monthly),
          },
        }
      : {
          startOn: "",
          endOn: "",
          rate: {
            daily: "",
            weekly: "",
            monthly: "",
          },
        },
    vendorContact: asset?.vendorContact || {},
    category: asset?.category || AssetCategory.Other,
  }

  const onSubmit = async (values: Values, { setErrors }: { setErrors: (args: UniqueIdErrors) => void }) => {
    const { name, companyAssetNumber, ownershipType, vendorContact, category } = values

    // @ts-ignore
    delete vendorContact["__typename"]

    const inventoryRequirements: Asset["inventoryRequirements"] | undefined =
      values.inventoryRequirements && handleInventoryRequirementsSubmission(values.inventoryRequirements)
    const purchaseDetails: Asset["purchaseDetails"] | undefined =
      ownershipType === "OWN"
        ? {
            date:
              values.purchaseDetails?.date && values.purchaseDetails.date instanceof Date
                ? format(values.purchaseDetails.date, "yyyy-MM-dd")
                : "",
            price: values.purchaseDetails?.price ? formatNumberFromMoney(values.purchaseDetails.price) : undefined,
          }
        : undefined

    const rentalAgreement: Asset["rentalAgreement"] | undefined =
      ownershipType === "RENT"
        ? {
            startOn: values.rentalAgreement.startOn
              ? format(new Date(values.rentalAgreement.startOn), "yyyy-MM-dd")
              : "",
            endOn: values.rentalAgreement.endOn ? format(new Date(values.rentalAgreement.endOn), "yyyy-MM-dd") : "",
            rate: {
              daily: formatNumberFromMoney(values.rentalAgreement?.rate?.daily),
              weekly: formatNumberFromMoney(values.rentalAgreement?.rate?.weekly),
              monthly: formatNumberFromMoney(values.rentalAgreement?.rate?.monthly),
            },
          }
        : undefined

    const manufacturer = values.manufacturer
      ? {
          id: values.manufacturer.id || undefined,
          make: values.manufacturer.make,
          name: values.manufacturer.name,
          year: values.manufacturer.year ? parseInt(values.manufacturer.year) : undefined,
          model: values.manufacturer.model,
        }
      : {}

    const inspectionRequirements = values.inspectionRequirements.assetReportTemplateId
      ? [
          {
            assetReportTemplateId: values.inspectionRequirements.assetReportTemplateId,
            intervalInSeconds: Number(values.inspectionRequirements.intervalInSeconds) || undefined,
            startDate: values.inspectionRequirements?.startDate,
          },
        ]
      : []

    let photoId = !initialAssetImageSrc ? "" : undefined
    if (assetImageSrc) {
      const imageBlob = dataURLToBlob(assetImageSrc)
      const uploadedFile = await fileUpload(`asset-image/${asset.id}`, "image/webp", imageBlob)
      photoId = uploadedFile?.fileId || undefined
    }

    editAssetMutation({
      id: asset.id,
      name,
      category,
      companyAssetNumber: companyAssetNumber || undefined,
      ownershipType,
      photoId,
      inventoryRequirements,
      manufacturer,
      purchaseDetails,
      rentalAgreement,
      vendorContact,
      inspectionRequirements,
    }).then((result) => {
      if (result.error) {
        handleValidationErrors(result.error.message, setErrors)
        errorSnack("Unexpected error; please try again")
      } else {
        successSnack("Asset update complete")
        setAssetImageSrc("")
        handleClose()
      }
    })
  }

  return (
    <Formik
      initialTouched={{
        companyAssetNumber: true,
        manufacturer: {
          id: true,
        },
      }}
      initialValues={initialValues}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <DrawerHeader handleClose={handleClose} href={`/assets/${asset?.id!}/details`} />

          <DrawerBody>
            <H2 className="mb-6 text-gray-800">Edit Asset</H2>
            <DevelopmentFeatureFlag name="Asset Category">
              <div className="mb-6">
                <SectionTitle label="Category" />
                <ChipSelect name="category" options={assetCategoryOptions} />
              </div>
            </DevelopmentFeatureFlag>
            <SectionTitle label="Basic Info" />

            <FormRow childrenContainerClassName="flex flex-col mb-6">
              <ImageUpload
                onFileSelected={([file]) => {
                  if (file) {
                    getDataUrlForImage(file, (dataUrl) => setAssetImageSrc(dataUrl), 600, 600)
                  }
                }}
                onDelete={() => {
                  setAssetImageSrc("")
                  setInitialAssetImageSrc("")
                }}
                imageSrc={assetImageSrc}
                initialImageSrc={initialAssetImageSrc}
                accept={"accept"}
              />
            </FormRow>
            <FormRow>
              <TextField fullWidth name="name" label="Asset name" value={values.name} />
            </FormRow>
            <FormRow>
              <TextField fullWidth name="companyAssetNumber" label="Asset number" />
            </FormRow>

            {values.groupQuantity && parseInt(values.groupQuantity || "1") <= 1 && (
              <>
                <SectionTitle label="Other Details" />

                <FormRow>
                  <TextField
                    fullWidth
                    label={
                      [AssetCategory.Vehicles, AssetCategory.Trailers].includes(values.category)
                        ? "VIN #"
                        : "Vin or serial #"
                    }
                    name="manufacturer.id"
                  />
                </FormRow>
                <FormRow>
                  <TextField
                    fullWidth
                    name="manufacturer.year"
                    label="Year"
                    inputProps={{
                      min: 1900,
                      max: new Date().getFullYear() + 2,
                    }}
                  />
                </FormRow>
                <FormRow>
                  <TextField fullWidth name="manufacturer.make" label="Manufacturer" />
                </FormRow>
                <FormRow>
                  <TextField fullWidth name="manufacturer.model" label="Model" />
                </FormRow>

                <SectionTitle label="Owned / Rented" />

                <FormRow>
                  <RadioGroup
                    name="ownershipType"
                    options={[
                      {
                        value: "OWN",
                        label: "Owned",
                      },
                      {
                        value: "RENT",
                        label: "Rented",
                      },
                    ]}
                  />
                </FormRow>

                {values.ownershipType === "OWN" && (
                  <div className="mt-4">
                    <FormRow>
                      <TextField fullWidth name="purchaseDetails.price" label="Purchase price" />
                    </FormRow>

                    <FormRow>
                      <DatePicker
                        name="purchaseDetails.date"
                        format="MM/dd/yyyy"
                        label="Purchase date"
                        sx={{ width: "100%" }}
                      />
                    </FormRow>
                  </div>
                )}

                {values.ownershipType === "RENT" && (
                  <div className="mt-4">
                    <FormRow>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            name="rentalAgreement.startOn"
                            format="MM/dd/yyyy"
                            label="Rental start on"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePicker
                            sx={{ width: "100%", marginBottom: "1rem" }}
                            name="rentalAgreement.endOn"
                            format="MM/dd/yyyy"
                            label="Rental end on"
                          />
                        </Grid>
                      </Grid>
                    </FormRow>
                    <FormRow childrenContainerClassName="flex-col">
                      <div className="grid lg:grid-cols-3 gap-4">
                        <TextField name="rentalAgreement.rate.daily" label="Daily rate" type="money" />
                        <TextField name="rentalAgreement.rate.weekly" label="Weekly rate" type="money" />
                        <TextField name="rentalAgreement.rate.monthly" label="4-week rate" type="money" />
                      </div>
                    </FormRow>
                    <FormRow>
                      <TextField fullWidth name="vendorContact.name" label="Vendor name" />
                    </FormRow>
                    <FormRow>
                      <TextField fullWidth name="vendorContact.email" type="email" label="vendor@example.com" />
                    </FormRow>
                    <FormRow>
                      <TextField fullWidth name="vendorContact.phone" label="(888) 888-8888" type="tel" />
                    </FormRow>
                  </div>
                )}
              </>
            )}

            <div>
              <SectionTitle label="Inventory Checklist" />
              <InventoryRequirementsForm isCheckedByDefault={!!values.inventoryRequirements?.intervalInSeconds} />
            </div>

            <div>
              <SectionTitle label="Inspection Checklist" />
              <AssetInspectionForm asset={asset} />
            </div>

            <DrawerFooter>
              <div className="flex flex-wrap items-center gap-x-8 gap-y-3">
                <ButtonFilled
                  color="primary"
                  type="submit"
                  disabled={createIsLoading || editIsFetching || isSubmitting}
                >
                  {isSubmitting ? "Saving" : "Update"}
                </ButtonFilled>
                <Button variant="text" type="button" onClick={() => handleClose()} size="large">
                  Cancel
                </Button>
              </div>
            </DrawerFooter>
          </DrawerBody>
        </Form>
      )}
    </Formik>
  )
}

type SectionTitleProps = {
  label?: string
}

const SectionTitle: FC<SectionTitleProps> = ({ label }) => {
  return (
    <>
      {label && <H5>{label}</H5>}
      <hr className="mb-8" />
    </>
  )
}
